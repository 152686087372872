/* You can add global styles to this file, and also import other style files */
@import "app/_styles/theme.scss";
html,
body {
    height: 100%;
    overflow-x: hidden;
}
body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}
.ghost-button:hover {
    span {
        color: #000 !important;
        position: relative;
        z-index: 999;
    }
}
