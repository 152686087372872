@import "~@angular/material/theming";
@include mat-core();

// Colours

$primary-color-100: lighten(#f6d437, 10%);
$primary-color-500: #f6d437;
$primary-color-600: darken(#f6d437, 10%);
$primary-color-700: darken(#f6d437, 20%);
$primary-color-800: darken(#f6d437, 30%);

$accent-color-100: lighten(#222, 10%);
$accent-color-500: #222;
$accent-color-700: darken(#222, 20%);

// Typograpghy

$font-family: Roboto, "Helvetica Neue", sans-serif;
$dark-text: #222;
$light-text: #fff;
$link-text: #fff;
$link-text-hover: $primary-color-700;

/*
    core styles
*/

a {
    color: $link-text;
    text-decoration: none;
    transition: all 0.2s linear;
    &:hover {
        color: $link-text-hover;
        padding-bottom: 3px;
        border-bottom: 1px solid $link-text-hover;
        transition: all 0.2s linear;
    }
}

/*
    Everything below here is for themeing Material design
    using the variables above
*/

$mat-red-overwrite: (
    100: #ffcdd2,
    500: #ac1f1e,
    700: #d32f2f,
    contrast: (
        100: $dark-text,
        500: $light-text,
        700: $light-text,
    ),
);

$primary-palette: (
    100: $primary-color-100,
    500: $primary-color-500,
    700: $primary-color-700,
    contrast: (
        100: black,
        500: black,
        700: black,
    ),
);

// accent palette
$accent-palette: (
    100: $accent-color-100,
    500: $accent-color-500,
    700: $accent-color-700,
    contrast: (
        100: white,
        500: white,
        700: white,
    ),
);

$typography-config: mat-typography-config(
    $font-family: $font-family,
);

// Define the app theme.
$primary: mat-palette($primary-palette, 500, 100, 700);
$accent: mat-palette($accent-palette, 500, 100, 700);
$warn: mat-palette($mat-red-overwrite);
$app-theme: mat-dark-theme($primary, $accent, $warn);

// Include the default theme styles.
@include angular-material-theme($app-theme);
@include angular-material-typography($typography-config);

//override

/* Button overrides */
.mat-button:hover .mat-button-focus-overlay,
.mat-stroked-button:hover .mat-button-focus-overlay {
    opacity: 1 !important;
}

.mat-raised-button {
    border: 1px solid $dark-text;
}

.mat-raised-button.mat-primary:hover {
    background-color: $primary-color-600;
}

.mat-raised-button.mat-accent:hover {
    background-color: $accent-color-100;
}

::ng-deep .mat-stroked-button.mat-primary {
    color: #fff;
    height: 51px;
    width: 60%;

    &:not([disabled]) {
        border-color: rgba(255, 255, 255, 1);
        border-width: 2px;
    }

    &:hover {
        .mat-button-wrapper {
            color: $dark-text !important;
        }
        color: $dark-text;
        background-color: $primary-color-500;
        border-color: $primary-color-500;

        .mat-button-focus-overlay {
            opacity: 0;
            display: none;
        }
    }
}

.mat-form-field,
button {
    width: 100%;
}

button {
    height: 51px;
    &:hover {
        cursor: pointer;
    }
    &:disabled {
        &:hover {
            cursor: not-allowed;
            background-color: #333 !important;
        }
    }
}

// radio

.mat-radio-label {
    margin-left: 10px;
}


.mat-checkbox {
    label {
        color: #fff;
    }
    .mat-checkbox-frame {
        border-color: #ccc;
    }
    .mat-checkbox-checkmark-path {
        stroke: #111 !important;
    }
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: #f6d437 !important;
}


// .mat-input-element {
//     caret-color: #f6d437 !important;
// }